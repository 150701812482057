import React from "react";
import "./Benefits.css";
export default function Benefits() {
	return (
		<div className='benifits '>
			<h1 className='benifitsHeader d-flex align-items-center justify-content-center'>
				One Hour, Multiple Benefits
			</h1>
			<div className='d-flex flex-column flex-md-row flex-wrap '>
				<div className='col-12 col-sm-12 col-md-12 col-lg-4 benifitBox'>
					<div>
						<div className='d-flex align-items-center justify-content-center'>
							<img
								src='assets/medit.svg'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='title text-center my-2'>
						The Best of Traditional Yoga—Amplified
							{/* <br className='d-none d-sm-block' /> Yoga */}
						</div>
						<div className='description'>
						Unlocks higher energy levels, promotes a deep sense of lightness, balances hormones, strengthens immune system, and enhances flexibility!
						</div>
					</div>
				</div>
				<div className='col-12 col-sm-12 col-md-12 col-lg-4 benifitBox'>
					<div>
						<div className='d-flex align-items-center justify-content-center'>
							<img
								src='assets/dumble.svg'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='title text-center my-2'>
						Full-Body Workout—Beyond Just Yoga

							{/* <br className='d-none d-sm-block' /> Workout */}
						</div>
						<div className='description'>
						Achieve weight loss, inch loss, and fat reduction while sculpting lean, toned muscles. Boost stamina, cardiovascular endurance, and overall physical fitness with a dynamic practice that delivers real results!
						</div>
					</div>
				</div>
				<div className='col-12 col-sm-12 col-md-12 col-lg-4 benifitBox'>
					<div>
						<div className='d-flex align-items-center justify-content-center'>
							<img
								src='assets/lotus.svg'
								alt=''
								className='img-fluid'
							/>
						</div>
						<div className='title text-center my-2'>
						The Transformative Power of Meditation

							{/* <br className='d-none d-sm-block' /> Meditation */}
						</div>
						<div className='description'>
						Experience mental clarity, emotional balance, deep restful sleep, and reduced stress levels. Elevate your mind, calm your emotions, and unlock a state of inner peace.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
