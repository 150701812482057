import React from 'react'
import "./studioPrograms.css"
import Slider from "react-slick";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        // className={className}
        // style={{display: "block", background: "green" }}
        // onClick={onClick}
      >
        <img style={{height:"auto"}} className={className} src="/assets/rightArrow.png" alt="" onClick={onClick}/>
    </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
    <>
      <div
        // className={className}
        // style={{display: "block", background: "green" }}
        // onClick={onClick}
      >
        <img style={{...style,height:"auto"}} className={className} src="/assets/leftArrow.png" alt="" onClick={onClick}/>
    </div>
      
    </>
    );
  }


const StudioPrograms = () => {

    const programs = [
        {
            image:"assets/SuperFast.jpg",
            name:"SuperFAST",
            description:"This intense, mega successful program gives you MIRACULOUS results in a relatively short span of time. Some of our clients lost over 11 kgs in just 4 weeks!"
        },
        {
            image:"assets/RED.jpg",
            name:"Red",
            description:"Our Power-packed weight-loss classes that tone up your muscles, and balance your hormones so that you lose weight but feel happy and energised."
        },
        {
            image:"assets/Rapid.jpg",
            name:"Rapid",
            description:"This 1-week program will detox and reset your system, and kickstart your fitness journey. Ideal for frequent travellers and those who took a break from their fitness routine."
        },
        {
            image:"assets/AY Classic.jpg",
            name:"AY Classic",
            description:"Our medium intensity classes specifically designed to open up your body progressively and takes you deeper into traditional postures and breathing techniques."
        }
    ]


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
                breakpoint: 830,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  nextArrow:null,
                  prevArrow:null,
                  initialSlide: 1
                }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                nextArrow:null,
                prevArrow:null,
                initialSlide: 2,
                dots: true
              }
            },
            {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
            },
        ]
      };

  return (
    <div className='StudioPrograms'>
        <div className="studioProgramsHeader">
            <h2>The Following Programs Are Available At Our Studios</h2>
        </div>

        <div className="programContainer">
            <Slider {...settings}>
            {
                programs.map((item) => (
                    <div className="programs" style={{height:"100% !important"}}>
                         <img src={item.image} alt="" />
                         <h4>{item.name}</h4>
                         <p>{item.description}<span></span> </p>
                    </div>
                ))
            }
            </Slider>
            
        </div>

        <div className="chatWithExpert">
            <h4>Discover The Perfect Program to Suit Your Needs</h4>
            <div className="chatBtn">
              <a href="https://wa.me/971568819394?text=Hi%20" style={{textDecoration:"none"}}>
                <p> Chat With Our Experts</p>
              </a>
            </div>
        </div>

    </div>
  )
}

export default StudioPrograms