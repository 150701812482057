import React,{useState} from "react";
import { motion, useAnimation } from "framer-motion";
import "./HemburgerMenu.css";
export default function HemburgerMenu({ animation,modalHandler }) {
	const [SelectedMenu, setSelectedMenu]=useState("home")
	const handleWhatsapp = () => {
		window.open("https://wa.me/971568819394?text=Hi%20", "_blank");
	};
	const close = () => {
		const body = document.querySelector("body");
		body.classList.remove("stopScroll");
		animation.start({
			x: "200%",
		});
		return;
	};
	function scrollToTargetAdjusted(targetElement) {
		var element = document.getElementById(`${targetElement}`);
		var headerOffset = 85;
		var elementPosition = element.getBoundingClientRect().top;
		var offsetPosition =
			elementPosition + window.pageYOffset - headerOffset;

		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth",
		});
	}
	const handleNavClick = (e) => {
		e.preventDefault();
		close();
		console.log(e.target.dataset.target, e.currentTarget);
		const target = e.currentTarget.dataset.target;
		setSelectedMenu(target)
		scrollToTargetAdjusted(target);
		// const scrollto = document.querySelector(`#${target}`);
		// scrollto.scrollIntoView();
	};
	return (
		<motion.div
			className='burgerMenu position-fixed'
			initial={{
				x: "200%",
			}}
			animate={animation}
			transition={{ duration: 0.5 }}>
			<div className='burgerMenunav d-flex align-items-center justify-content-between px-md-5 p-3'>
				<div className='navBrand'>
					<img
						src='assets/Ay-Mobile.svg'
						alt=''
						className='img-fluid'
					/>
				</div>
				<div className='d-flex align-items-center'>
					<div className='joinToday' onClick={() => modalHandler(true)}>Join Today</div>
					<div
						style={{ cursor: "pointer" }}
						className='d-block d-sm-none ms-3'
						onClick={close}>
						<img
							src='assets/close.svg'
							alt=''
							className='img-fluid'
						/>
					</div>
				</div>
			</div>
			<div className='w-100 h-100 p-5'>
				{/* <div onClick={close}>&times;</div> */}
				{/* <div className='d-flex align-items-center justify-content-center'>
					<img
						src='assets/Ay-Mobile.svg'
						alt=''
						className='img-fluid'
					/>
				</div> */}
				<div>
					<div
						data-target='home'
						onClick={handleNavClick}
						className={`menuItem ${SelectedMenu=="home"&&'home'} `}>
						Home
					</div>
					<div
						data-target='testimonials'
						onClick={handleNavClick}
						className={`menuItem ${SelectedMenu=="testimonials"&&'home'} `}>
						Testimonials
					</div>
					<div
						data-target='whyred'
						onClick={handleNavClick}
						className={`menuItem ${SelectedMenu=="whyred"&&'home'} `}>
						Why AY
					</div>
					<div
						data-target='oneHourRed'
						onClick={handleNavClick}
						className={`menuItem ${SelectedMenu=="oneHourRed"&&'home'} `}>
						One Hour with AY
					</div>
					<div
						data-target='faq'
						onClick={handleNavClick}
						className={`menuItem ${SelectedMenu=="faq"&&'home'} `}>
						FAQ
					</div>
					<div className='contact  '>
						<div
							onClick={handleWhatsapp}
							className='d-flex align-items-center '>
							<span className='phone'>
								<img
									src='assets/call.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='mx-2 text'>or</span>
							<span className='whatsapp me-2'>
								<img
									src='assets/whatsapp.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='number text'>+971568819394</span>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}
