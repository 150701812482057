import React, { useEffect, useState } from 'react'
import "./homeBanner.css"
import "react-phone-input-2/lib/style.css";
import useGeoLocation from "react-ipgeolocation";
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useHistory } from 'react-router-dom';
// import TagManager from 'react-gtm-module'

const HomeBanner = ({setShowThanksPopUp}) => {

    const analytics = getAnalytics()
    const history = useHistory()

    const location = useGeoLocation();
    const [error, setError] = useState('')
	const [isEmailOk, setIsEmailOk] = useState("")
    const [isDisable, setisDisable] = useState(true);

    const [phoneData, setPhoneData] = useState(null);
	const [code, setcode] = useState(undefined);
    const [formData, setFormData] = useState({
		email: null,
		name: null,
		mobile: null,
		isd: null,
	});


    // ===============Initialize tag manager===================//
    // const tagManagerArgs = {
    //     gtmId: 'AW-11082418360',
    // }
    
    // TagManager.initialize(tagManagerArgs)


    // =================Condition check=========================//
    const check = (data) => {
		let flag = false;
		Object.keys(data).forEach((item) => {


			if (/^[A-Za-z0-9+_.-]+@(.+)$/.test(data[item])) {
				setIsEmailOk("ok")
				console.log("ok")
			}

			if (isEmailOk == "ok") {
				console.log("mbloutside")
				if (formData?.mobile?.length >= 5) {
					console.log("mbl")
					setisDisable(false)
				}
			}
		});
	};

    const handleFormData = (e) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;

		setFormData((prev) => {
			const newData = { ...prev };
			newData[name] = value;
			check(newData)

			return newData;
		});

	};

    const handleChange = (value, data, event, formattedValue) => {
		const mobile = value.slice(data.dialCode.length)
		setPhoneData(mobile)
		setFormData((prev) => ({ ...prev, mobile }))
		setcode(data.dialCode);
	};


    useEffect(() => {
		check(formData)

	}, [formData.mobile])



    const handleBookaClass = (e) => {

        logEvent(analytics,"Clicked on Book Now button: Studio Page",formData)


        // ==================Open Google tag manager====================//
            const tagData = {
                event: "Clicked on Book Now button: Studio Page",
                formData
            }
        
            // TagManager.dataLayer({dataLayer:tagData})
        // ==================Close Google tag manager====================//

        setisDisable(true)
        let data = formData;
		data['isd'] = code;
		data['packageId'] = process.env.REACT_APP_PACKAGE_ID;
		if (process.env.REACT_APP_PROMOTION_ID != "null") {
			data['promotionId'] = process.env.REACT_APP_PROMOTION_ID;
		}

        const submitData = JSON.stringify(data);
		const privyrData =  JSON.stringify({...data,lead_source: "Studio Page",phone:phoneData})
		console.log(privyrData)

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://www.privyr.com/integrations/api/v1/incoming-webhook',
            headers: { 
              'X-TOKEN': 'hp4iWYK7', 
            //   'X-TOKEN': 'ACRXGO9m', 
              'Content-Type': 'application/json'
            },
            data : privyrData
          };
          
          axios.request(config)
          .then((response) => {
            console.log(response)
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
        


        // ====================Payemnt Link Api=============================//
        // axios.post(process.env.REACT_APP_JOIN_NOW_URL,
        //     submitData,
        //     {
        //         headers: {
        //             "Content-Type": "application/json",
        //         },
        //     }
        // )
        // .then((res) => {
        //     console.log("Join now Url",res)
        //         setisDisable(false)
        //         setFormData((prevState) => ({
        //             ...prevState,
        //             // name: null,
        //         })); 
        // })
        // .catch((err) => {
        //     console.log(err)
        // })

        // ====================Register User Api==========================//

        const NewFormData = {
            ...data, campaignId: process.env.REACT_APP_CAMPAIGNID, packageId: process.env.REACT_APP_PACKAGE_ID,
            originCampaign:process.env.REACT_APP_ORIGIN_CAMPAIGN,
        }
        const SubmitNewFormData = JSON.stringify(NewFormData)

        axios.post('https://ser.ay.tudotechnologies.com/magicFood/registerUserPayNow/', 
        // axios.post('https://qrs.btartisticyoga.com/magicFood/registerUserPayNow/', 
            SubmitNewFormData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => {
            console.log("Register User",res)
            setisDisable(false)
                setFormData((prevState) => ({
                    ...prevState,
                })); 
            history.push("/ThankyouPage")
        })
        .catch((err) => {
            console.log(err)
            setisDisable(false)
        });

    }





  return (
    <>
    <div className="respstudioBannerImg">
            <img src="/assets/coverMobile.png" alt="" />
    </div>
    <div className='HomeBanner'>
        <div className="studioBannerImg">
            <img src="/assets/studioBannerImg.png" alt="" />
        </div>

        

        <div className="HomeBannerContainer">


        <div className="yogaClassDescription">
            {/* <div className="studioSession">
                <h3>STUDIO SESSIONS</h3>
            </div> */}
        
            <h2>BTAY Studio Sessions</h2>

            <h3>Transform Your Body, Mind & Energy with Artistic Yoga</h3>
            <p className='para'>
            Looking for a yoga class that truly delivers? Developed by the living Himalayan master Dr. Bharat Thakur, Artistic Yoga blends cutting-edge workout techniques with ancient yogic wisdom. Whether you want to boost metabolism, tone muscles, enhance flexibility, or de-stress, our unique approach works on the deepest layers of your body and mind.
            </p>

            {/* <div className="studioImgResp BookTrailstudioFormResp">
                
                <h2>Book a Free trial Now</h2>

                <div className="studioTrailInputs">
                    <label htmlFor="">Name</label>
                    <input name='name' type="text" placeholder='Enter your name' onChange={handleFormData}/>
                </div>

                <div className="studioTrailInputs">
                    <label htmlFor="">Email Address</label>
                    <input name='email' type="text" placeholder='Enter your email'  onChange={handleFormData}/>
                </div>

                <div className="studioTrailInputs">
                    <label htmlFor="">Phone Number</label>
                    <PhoneInput
                            // @ts-ignore
                            className="joinNowinput"
                            onChange={handleChange}
                            placeholder='Enter your mobile'
                            type='text'
                            id='Mobile'
                            country={location?.country?.toLowerCase()}
                        />
                </div>

                <button 
                disabled={isDisable} 
                onClick={handleBookaClass}
                style={isDisable ? {background:"linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%)",opacity:"0.2"} : {background:"linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%)"}}
                >Book Now</button>
            </div> */}

            <div className='benefitsContainerParent'>

            <h3>Why Choose Artistic Yoga?</h3>

            <div className="benefitsContainer">
                <div className="benefits">
                    <img src="assets/focusedProgram.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>Focused Programs</h4>
                       
                            <span>
                            Tailored to your goals—Reduce Every Day (Weight Loss), SuperFAST, Himalayan Challenge, Classic, RAPID, Meditation & Breathing.
                            </span>
                      
                        
                    </div>
                </div>

                <div className="benefits">
                    <img src="assets/fantasticTeacher.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>Expert Teachers</h4>
                        <span>
                        Highly trained instructors with decades of experience and deep yogic wisdom.
                        </span>
                    </div>
                </div>

            </div>

            <div className="benefitsContainer">

                <div className="benefits">
                    <img src="assets/results.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>Proven Results</h4>
                        <span>
                        Transforming lives for over 20 years with real, lasting changes.
                        </span>
                    </div>
                </div>

                <div className="benefits">
                    <img src="assets/gift.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>Flexible Packages
                        </h4>

                        <span>
                        A variety of membership options to fit every budget.

                        </span>
                    </div>
                </div>

            </div>



            <div className="benefitsContainer">
                <div className="benefits">
                    <img src="assets/convenientLocation.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>Convenient Locations</h4>
                        <span>
                        Studios near you with FREE PARKING & SHOWER FACILITIES.

                        </span>
                    </div>
                </div>

                <div className="benefits">
                    <img src="assets/convenientTime.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>Morning & Evening Sessions
                        </h4>
                        <span>
                        Fits perfectly into your busy schedule.
                        </span>
                    </div>
                </div>

            </div>

        </div>

        </div>
        <div className="studioImg BookTrailstudioForm">

            <h2>Book Your Free Trial Today!
            </h2>

            <h3>Experience the power of Artistic Yoga and start your transformation.</h3>

            <div className="studioTrailInputs">
                <label htmlFor="">Name</label>
                <input name='name' type="text" placeholder='Enter your name' onChange={handleFormData}/>
            </div>

            <div className="studioTrailInputs">
                <label htmlFor="">Email Address</label>
                <input name='email' type="text" placeholder='Enter your email'  onChange={handleFormData}/>
            </div>

            <div className="studioTrailInputs">
                <label htmlFor="">Phone Number</label>
                <PhoneInput
						// @ts-ignore
						className="joinNowinput"
						onChange={handleChange}
						placeholder='Enter your mobile'
						type='text'
						id='Mobile'
						country={location?.country?.toLowerCase()}
					/>
            </div>

            <button 
            disabled={isDisable} 
            onClick={handleBookaClass}
            style={isDisable ? {background:"linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%)",opacity:"0.2"} : {background:"linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%)"}}
            >Book Now</button>


            {error && <div className="warning">
					<div className="d-flex justify-content-center align-items-center ">
						
						<p>*{error}</p>
					</div>

				</div>}
        </div>

        

        </div>

    </div>
    </>
  )
}

export default HomeBanner