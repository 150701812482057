import React,{useRef,useState,useEffect} from 'react'
import Nav from "../Nav/Nav";
import JoinNowModal from "../JoinNowModal/JoinNowModal";
import RedBanner from "../RedBanner/RedBanner";
import ClientsReview from "../ClientsReview/ClientsReview";
import Advertisement from "../Advertisement/Advertisement";
import Benefits from "../Benefits/Benefits";
import WhyRed from "../WhyRed/WhyRed";
import HourDayComparism from "../HourDayComparism/HourDayComparism";
import OurTeachers from "../OurTeachers/OurTeachers";
import AboutBharatThakur from "../AboutBharatThakur/AboutBharatThakur";
import FAQ from "../FAQ/FAQ";
import DownloadEbooknew from "../DownloadEbook/DownloadEbooknew";
import Footer from "../Footer/Footer";
import HomeBanner from '../HomeBanner/HomeBanner';
import StudioPrograms from '../StudioPrograms/StudioPrograms';
import OurStudios from '../OurStudios/OurStudios';
import SpecialOffers from '../SpecialOffers/SpecialOffers';
import ThankYouPopUp from "../ThankYouPopUp/ThankYouPopUp";
import EbookDownload from '../EbookDownload/EbookDownload';
import EbookModal from '../EbookDownload/EbookModal/EbookModal';
// import h337 from "heatmap.js";
import useWindowSize from 'use-window-size-v2'


const HomePage = () => {

    const [modalShow, setModalShow] = React.useState(false);
    const [ShowThanksPopUp, setShowThanksPopUp] = React.useState(false);

    const ThankYouPopUpHide = () => setShowThanksPopUp(false);
    const ThankYouPopUpShow = () => setShowThanksPopUp(true);


  //   const [coords,setCoords] = useState([])
  //   const [time,setTime] =  useState(1)
  
  //   // function mouseStopped(){
      
  //   // 	// console.log(timer)
  //   // }
  
  
  // const mouseXY = async e =>{
  //   // setTime(0)
  //   // clearTimeout(timer);
  //   //   timer=setTimeout(mouseStopped,50);
  //     setCoords((prevState) => [...prevState, { x: e.clientX, y: e.clientY,val:time }]);
  // }
  
  
  
  // useEffect(()=>{
  //     window.addEventListener('mousemove', mouseXY);
  // },[time,coords])
  
  // const heatMapCont = document.getElementById(".home")
  
  // const elementRef = useRef(null);
  
  
  // const handleStopEvent = () => {
  //   window.removeEventListener('mousemove', mouseXY);
  
  //   if(coords){
  
  //      var heatmapInstance = h337.create({
  //       // only container is required, the rest will be defaults
  //       container: document.querySelector('.home')
  //     });
  //     // now generate some random data
     
  //     var max = 5;
  //     var width = elementRef.current.clientWidth;
  //     var height = elementRef.current.clientHeight;
  //     var len = 200;
  
  
  //    let pp = coords.filter( (ele, ind) => ind === coords.findIndex( elem => elem.x === ele.x && elem.y === ele.y))
  //    console.log(pp)
  //     var data = {
  //       max: max,
  //       data: pp
  //     };
  //     // if you have a set of datapoints always use setData instead of addData
  //     // for data initialization
  //     heatmapInstance.setData(data);
  //   }
  // }

  const [ebookPopUp,setEbookPopUp] = useState(false)

  const handleWhatsapp = () => {
		window.open("https://wa.me/971585841952?text=Hi%20", "_blank");
	};

	const handleCall = () => {
		window.open("tel:+971585841952","_blank");
	}

  const [isVisible,setIsVisible] = useState(false)
  const { width } = useWindowSize(100);
  useEffect(() => {
      if(width < 769){
        setIsVisible(true)
      }
      else{
        setIsVisible(false)
      }
  }, [])
  


  return (

    <>
    {/* <button className='heatMapBtn' style={{position:"fixed",top:"90px"}} onClick={handleStopEvent}>Show HeatMap</button> */}
       <JoinNowModal ThankYouPopUpShow={ThankYouPopUpShow} show={modalShow} onHide={() => setModalShow(false)} />
       <ThankYouPopUp ThankYouPopUpHide={ThankYouPopUpHide} ShowThanksPopUp={ShowThanksPopUp} />

       {ebookPopUp == true && <EbookModal setEbookPopUp={setEbookPopUp}/>}

      <div className='home' id='home'>
				<Nav modalHandler={setModalShow} />
				
                    <HomeBanner setShowThanksPopUp={setShowThanksPopUp}/>
                    <div className='px-3 px-sm-5'>
                    <RedBanner modalHandler={setModalShow} />
                    <StudioPrograms/>
                    <ClientsReview/>
                    <Benefits />
					          <Advertisement modalHandler={setModalShow} type={1} />
                    <WhyRed />
                    <Advertisement modalHandler={setModalShow} type={2} />
                    <HourDayComparism />
                    <OurTeachers />
                    <Advertisement modalHandler={setModalShow} type={3} />
                    <SpecialOffers/>
                    <AboutBharatThakur />
                    <OurStudios/>
                    <FAQ />
                    <Advertisement modalHandler={setModalShow} type={4} />
                </div>
                {/* <DownloadEbooknew/> */}
                <EbookDownload setEbookPopUp={setEbookPopUp}/>
				        <Footer />

                {
                  isVisible && 
                    <div  className="ChatOptionMobile">
                    <img width="79px"  src="/assets/whatsappFloat.png" alt="" onClick={handleWhatsapp}/> 
                    <img width="79px"  className="callFloat-icon" src="/assets/callFloat.png" alt="" onClick={handleCall}/> 
                  </div>
                }
                
            </div>
    </>
  )
}

export default HomePage